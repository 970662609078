import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout";
import Wrapper from "../../components/layouts/standard-wrapper";
import Resources from "../../components/layouts/resources";
import { BackButton } from "../../components/buttons/buttons";
import ReactModal from "react-modal";
import CloseIcon from "../../images/icons/close.svg";

// Replace with your own data source
import Data from "../../data/data";

const Container = styled.div`
  p {
    font-size: 0.9375rem;
  }
  h1 {
    padding: 2rem 0 0 0;
  }
  h2 {
    padding: 0 0 0.5rem 0;
    font-size: 1.875rem;
  }
`;
const Title = styled.section`
  padding: 0 0 1rem 0;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.uiPalette.dark.highGray};
`;

const Content = styled.section`
  .thank-you-row {
    padding: 5rem 5rem 4rem 5rem;
    text-align: center;
  }
  .resources-row {
    padding: 0 5rem 3rem 5rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .thank-you-row {
      padding: 3rem 2rem 2rem 2rem;
    }
    .resources-row {
      padding: 0 0 2rem 0;
    }
  }
`;

const Success = () => {
  const [modalVideoSrc, setModalVideoSrc] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Translation component
  const { t } = useTranslation();

  const setModalProperties = (videoSource) => {
    setModalVideoSrc(videoSource);
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <Wrapper>
        <Container>
          <Title>
            <BackButton />
            <h1>{t("request-new-project.h1")}</h1>
          </Title>
          <Content>
            <div className="thank-you-row">
              <h2>{t("success")}</h2>
              <p>{t("success.description")}</p>
            </div>
            <div className="resources-row">
              <Resources
                resourceData={Data.guide_data}
                showMore={false}
                resourceGrid={true}
                shortList={3}
                setModalProperties={setModalProperties}
              />
            </div>
          </Content>
        </Container>
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          className={"modal-content"}
          overlayClassName={"modal-overlay"}
          ariaHideApp={false}
        >
          <button
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="close-modal-button"
          >
            <CloseIcon aria-label="button" alt="Close icon" />
          </button>
          <iframe
            height="350vmin"
            width="660vmin"
            src={modalVideoSrc}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </ReactModal>
      </Wrapper>
    </Layout>
  );
};
export default Success;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "request", "buttons", "navigation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
